<template>
  <div>
    <div class="frmBlock__layout">
      <textarea
        class="frmBlock__textField frmBlock__textArea"
        :value="text"
        @input="updateOption($event.target.value)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object
    }
  },
  data() {
    return {
      text: ""
    };
  },
  computed: {
    initialValue() {
      return this.$route.path === "/option"
        ? this.option.componentOption.properties[0].initialValue
        : this.option.value.text;
    }
  },
  mounted() {
    this.text = this.initialValue;
    if (this.$route.path === "/option") {
      this.updateProperty(this.initialValue);
    }
  },
  methods: {
    // 프로퍼티 업데이트 (파트너)
    updateProperty(value) {
      let modified = {
        id: this.option.componentOption.id,
        properties: [
          {
            id: this.option.componentOption.properties[0].id,
            initialValue: value
          }
        ]
      };
      this.$emit("updateProperty", this.option.componentOption.key, modified);
    },
    // 옵션 Update
    updateOption(value) {
      this.text = value;
      this.$emit(
        "updateOption",
        this.option.componentOption.key,
        this.option.componentOption.properties[0].key,
        value
      );

      if (this.$route.path === "/option") {
        this.updateProperty(value);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
