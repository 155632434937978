var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "frmBlock__layout"
  }, [_c('textarea', {
    staticClass: "frmBlock__textField frmBlock__textArea",
    domProps: {
      "value": _vm.text
    },
    on: {
      "input": function input($event) {
        return _vm.updateOption($event.target.value);
      }
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }